import React, { FC } from "react";
import { Grid, Box } from "@mui/material";
import { AuthTemplateProps } from "./auth-template.types";
// import { margin } from "@mui/system";
import style from "./auth-template-style.module.css";
import { Navbar } from "@components/organisms";
import { DesktopFooter, MobileHeader } from "@components/organisms";
import { NavbarItems } from "@components/molecules";

export const AuthTemplate: FC<AuthTemplateProps> = ({ children }) => {
  return (
    <div>
      {/* Navbar */}
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "block",
          },
          position: "absolute",
          width: "100%",
          top: 0,
          background: "#ffffff",
          zIndex: 1,
        }}
      >
        <Navbar />
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "none",
          },
          position: "fixed",
          width: "100%",
          top: 0,
          background: "#ffffff",
          zIndex: 1,
        }}
      >
        <MobileHeader />
      </Box>
      {/* content area */}
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          //height: { xs: "calc(100vh - 70px)", sm: "calc(100vh - 100px)" },
          paddingTop: "60px",
          overflow: "scroll",
          paddingBottom: "60px",
        }}
      >
        <Grid
          container
          sx={{
            height: {
              xs: "auto",
              sm: "100%",
            },
          }}
        >
          <Grid
            item
            sx={{
              width: { xs: "100%", sm: "50%" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            className={style?.signup_col1}
          >
            <Grid
              container
              sx={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                sx={{
                  height: "100%",
                  padding: "0px 16px",
                  width: { xs: "100%", sm: "90%", md: "80%", lg: "60%" },
                  maxWidth: "400px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: {
                    xs: "20px",
                    sm: "0px",
                  },
                  justifyContent: {
                    xs: "flex-start",
                    sm: "center",
                  },
                }}
              >
                {children}
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                sx={{
                  width: { xs: "100%", sm: "100%" },

                  marginTop: "auto",
                }}
              >
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                    height: "60px",
                  }}
                >
                  <DesktopFooter />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            sx={{
              width: { xs: "100%", sm: "50%" },
              display: { xs: "none", sm: "block" },
            }}
            className={style?.auth_template_col2}
          ></Grid>
        </Grid>

        <Box
          sx={{
            display: {
              sm: "none",
            },
            borderTop: "1px solid #eaecf0",
            paddingTop: "10px",
            position: "fixed",
            bottom: 0,
            paddingBottom: "10px",
            width: "100%",
            background: "#ffffff",
          }}
        >
          <NavbarItems />
        </Box>
      </Box>
    </div>
  );
};
