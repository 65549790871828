"use client";

import React, { FC, Fragment, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@next/hooks";
import { authActions } from "@next/store";
import CookieConsent from "react-cookie-consent";
// import { useTheme } from "@mui/material";
import Link from "next/link";
import Snackbar from "@next/components/molecules/snackbar/snackbar";
import { useParams } from "next/navigation";
import { CustomLink } from "@components/molecules";
import { Typography } from "@mui/material";
import { useTranslation } from "i18n/client";
import { getCookie } from "cookies-next";
import { cookieName } from "i18n/settings";
import { getMetadata, lngType } from "@next/utils/getMetadata";

/**
 *
 * Features:
 * - Renders components which are common between all guest pages.
 *
 */
interface MainLayoutProps {
  children?: React.ReactNode;
}
export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const { loggedIn, authtoken } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const {t} = useTranslation("common")
  const params = useParams<{ lng: string }>();
  if (loggedIn && !localStorage.getItem("token")) {
    dispatch(authActions.logout());
  }
  useEffect(() => {
    const accountId = sessionStorage.getItem("AccountId");
    if (!accountId) {
      //Get new UUID and set
      const userId = crypto.randomUUID();
      sessionStorage.setItem("AccountId", userId);
    }
  }, []);
  useEffect(() => {
    const lang = params?.lng || getCookie(cookieName);
    if(lang){
      document.documentElement.setAttribute("lang", lang); //set the lang here
      let metaTag = document.querySelector('meta[http-equiv="content-language"]');
    if (!metaTag) {
      // If the meta tag doesn't exist, create it
      metaTag = document.createElement('meta');
      metaTag.setAttribute('http-equiv', 'content-language');
      document.head.appendChild(metaTag);
    }
    let ogMetaTag = document.querySelector('meta[property="og:site_name"]');
    if (!ogMetaTag) {
      // If the meta tag doesn't exist, create it
      ogMetaTag = document.createElement('meta');
      ogMetaTag.setAttribute('property', 'og:site_name');
      document.head.appendChild(ogMetaTag);
    }
    metaTag.setAttribute('content', lang);
    const metaData = getMetadata(lang as lngType, "landing_page")
    ogMetaTag.setAttribute('content',metaData["seo_title"])
  }
  },[params?.lng])
  if (loading) {
    return <div></div>;
  } else {
    return (
      <Fragment>
        {children}
        <Snackbar />
        <CookieConsent
          location="bottom"
          declineButtonText={t("cookie_banner.decline")} 
          buttonText={t("cookie_banner.accept")} 
          cookieName="localConsent"
          style={{ background: "#f62e8e", padding: "0px 6%" }}
          buttonStyle={{
            color: "#667085",
            fontSize: "13px",
            backgroundColor: "#ffffff",
            height: "36px",
            width: "fit-content",
            borderRadius: "8px",
             minWidth:"70px"
          }}
          declineButtonStyle={{
            color: "#fff",
            fontSize: "13px",
            backgroundColor: "transparent",
            height: "36px",
            width: "fit-content",
            borderRadius: "8px",
            border: "1px solid #fff",
            minWidth:"70px"
          }}
          expires={1} //days
          buttonWrapperClasses="cookie_btn_wrapper"
          enableDeclineButton={true}
          onAccept={() => {
            window !== undefined &&
              (window as any)?.gtag("consent", "update", {
                ad_storage: "granted",
                analytics_storage: "granted",
              });
          }}
        >
          {t("cookie_banner.text")}{" "}
          <Typography
            component="span"
            sx={{
              a: {
                color: "#ffffff",
                textDecoration: "underline",
              },
            }}
          >
            <CustomLink href="/privacy-policy" locale={params?.lng}>
              {t("cookie_banner.privacy_policy")}
            </CustomLink>
          </Typography>
        </CookieConsent>
      </Fragment>
    );
  }
};
