import { fallbackLng, languages } from "i18n/settings";

const metaData = {
  "en-us": {
    explore: {
      seo_title: "Explore the best experiences arount the world | Marveltime",
      seo_description:
        "Define your interest and we'll recommend the best experiences for you and your loved ones. Explore the world with us!",
    },
    contact: {
      seo_title: "Get in touch with our team today | Marveltime",
      seo_description:
        "Have questions or need assistance? Reach out to our friendly support team. We're here to help you with any inquiries, feedback, or support requests. Connect with us today!",
    },
    faq: {
      seo_title: "We are always available for questions | Marveltime",
      seo_description:
        "Whatever the topic, we are always here to answer your personal questions. Get in touch with us and our team will get back to you.",
    },
    help: {
      seo_title: "We will always help you | Marveltime",
      seo_description:
        "Whether it's a technical problem, questions about the content or you just need help, we are always there for you.",
    },
    imprint: {
      seo_title: "All legal information at a glance | Marveltime",
      seo_description:
        "We have compiled all the important legal information for you. If you have any further questions, please feel free to contact us.",
    },
    mission: {
      seo_title: "Our mission - Unforgettable experiences | Marveltime",
      seo_description:
        "We want to help you and your loved one create unforgettable memories for eternity through the best experiences in the world!",
    },
    landing_page: {
      seo_title: "Your All-in-One Platform for Experiences | Marveltime",
      seo_description:
        "Rate your interests, connect with your loved ones, discover perfect experiences, and create unforgettable memories together. Start now!",
    },
    privacy_policy: {
      seo_title: "Our privacy policy at a glance | Marveltime",
      seo_description:
        "We have compiled all the important information about our privacy policy for you. If you have any questions, please feel free to contact us.",
    },
    terms_of_use: {
      seo_title: "What you should know about its use | Marveltime",
      seo_description:
        "Here you will find all the important information about the terms of use of our platform. If you have any further questions, please feel free to contact us.",
    },
    not_found: {
      seo_title: "Oops - Page not found | Marveltime",
      seo_description:
        "Sorry, the page you are looking for doesn't exist. Check the URL or head back to our homepage to explore more. Need help? Contact our support team and we'll assist you.",
    },
   
  },
  "de-de": {
    explore: {
      seo_title:
        "Entdecke die besten Erlebnisse auf der ganzen Welt | Marveltime",
      seo_description:
        "Definiere deine persönlichen Interessen und wir empfehlen dir die besten Erlebnisse für dich und deine Liebsten. Entdecke die Welt mit uns!",
    },
    contact: {
      seo_title: "Nimm noch heute Kontakt mit unserem Team auf | Marveltime",
      seo_description:
        "Hast du Fragen oder brauchst Hilfe? Kontaktiere unser freundliches Support-Team. Wir sind hier, um dir bei allen Anfragen, Feedback oder Support-Anliegen zu helfen. Melde dich noch heute bei uns!",
    },
    faq: {
      seo_title: "Bei Fragen stehen wir immer zur Verfügung | Marveltime",
      seo_description:
        "Egal um welches Thema es sich handelt, wir sind immer für dich da, um deine persönlichen Fragen zu klären. Nimm Kontakt mit uns auf und unser Team meldet sich bei dir.",
    },
    help: {
      seo_title: "Wir helfen dir immer weiter | Marveltime",
      seo_description:
        "Egal ob es sich ein technisches Problmen handelt, Fragen zu den Inhalten vorliegen oder du einfach nur Hilfe benötigst, wir sind immer für dich da.",
    },
    imprint: {
      seo_title: "Alle rechtlichen Informationen auf einen Blick | Marveltime",
      seo_description:
        "Wir haben für dich alle wichtigen rechtlichen Informationen zusammengestellt. Falls du weitere Fragen hast, kannst du uns gerne kontaktieren.",
    },
    mission: {
      seo_title: "Unsere Mission - Unvergessliche Erinnerungen | Marveltime",
      seo_description:
        "Wir wollen dich und deinen Liebsten dabei unterstützen unvergessliche Erinnerungen für die Ewigkeit durch die besten Erlebnisse der Welt zu schaffen!",
    },
    landing_page: {
      seo_title: "Deine All-in-One Plattform für Erlebnisse | Marveltime",
      seo_description:
        "Bewerte deine Interessen, verbinde dich mit deinen Liebsten, entdecke perfekte Erlebnisse und schafft unvergessliche Erinnerungen zusammen. Jetzt starten!",
    },
    privacy_policy: {
      seo_title: "Unsere Datenschutzrichtlinie auf einen Blick | Marveltime",
      seo_description:
        "Wir haben für dich alle wichtigen Informationen zu unserer Datenschutzrichtlinie zusammengestellt. Bei Fragen kannst du uns gerne kontaktieren.",
    },
    terms_of_use: {
      seo_title: "Was du über die Nutzung wissen solltest | Marveltime",
      seo_description:
        "Hier findest du alle wichtigen Informationen zu den Nutzungsbedingungen unserer Plattform. Falls du weitere Fragen hast, kannst du uns gerne kontaktieren.",
    },
    not_found: {
      seo_title: "Oops - Seite nicht gefunden | Marveltime",
      seo_description:
        "Sorry, die Seite, die du suchst, existiert nicht. Überprüfe die URL oder gehe zurück zu unserer Startseite, um mehr zu entdecken. Brauchst du Hilfe? Kontaktiere unser Support-Team und wir helfen dir weiter.",
    },
  },
};

export type lngType = "en-us" | "de-de";
export type namespaceType =
  | "contact"
  | "explore"
  | "faq"
  | "help"
  | "imprint"
  | "mission"
  | "landing_page"
  | "privacy_policy"
  | "terms_of_use"
  | "not_found";
  
export const getMetadata = (lng: lngType, namespace: namespaceType) => {
  const locale = languages.includes(lng) ? lng : fallbackLng
  return metaData[locale][namespace];
};