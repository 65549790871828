import React, { FC } from "react";
import { Grid, Box } from "@mui/material";
import { MainTemplateProps } from "./main-template.types";
import { MobileHeader, PrivateNavbar } from "@components/organisms";
import { PrivateNavbarItems } from "@components/molecules";
import FooterPrimary from "@next/components/molecules/footer-primary/footer-primary";

export const PrivateTemplate: FC<MainTemplateProps> = ({
  children,
  background,
  showBackBtn,
  onClick,
}) => {
  return (
    <div>
      {/* Navbar */}
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "block",
          },
          position: "fixed",
          width: "100%",
          top: 0,
          zIndex: 2,
          background: "#ffffff",
        }}
      >
        <PrivateNavbar />
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "none",
          },
          position: "fixed",
          width: "100%",
          top: 0,
          zIndex: 2,
          background: "#ffffff",
        }}
      >
        <MobileHeader showBackBtn={showBackBtn} onClick={onClick} />
      </Box>
      {/* Content Area Start */}
      <Box
        sx={{
          minHeight: "100vh",
          background: background,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "1440px",
            margin: "0px auto",
            flexGrow: 1,
            paddingTop: "60px",
            paddingBottom: {
              xs: "70px",
              sm: "0px",
            },
          }}
        >
          {children}
        </Box>
        <Grid
          container
          sx={{
            width: "100%",
            display: {
              xs: "none",
              sm: "block",
            },
          }}
        >
          <Grid item xs={12}>
            <FooterPrimary />
          </Grid>
        </Grid>
      </Box>
      {/* Content Area End */}

      <Grid
        container
        sx={{
          position: "fixed",
          width: "100%",
          bottom: 0,
          zIndex: 2,
          background: "#ffffff",
          display: {
            sm: "none",
          },
        }}
      >
        <Grid
          item
          sx={{
            width: { xs: "100%", sm: "100%" },

            marginTop: "auto",
          }}
        >
          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "none",
              },

              borderTop: "1px solid #eaecf0",
              alignItems: "center",
              paddingTop: "3px",
              paddingBottom: "2px",
            }}
          >
            <PrivateNavbarItems />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
