import React, { FC } from "react";
import { Grid, Box } from "@mui/material";
import { Typography, SvgIcon, Button } from "@mui/material";
import { AuthTemplateProps } from "./main-template.types";
import { MobileHeader, Navbar } from "@components/organisms";
import { NavbarItems } from "@components/molecules";
import { DesktopFooter } from "@components/organisms";
import FooterPrimary from "@next/components/molecules/footer-primary/footer-primary";

export const MainTemplate: FC<AuthTemplateProps> = ({ children }) => {
  return (
    <div>
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "block",
          },
          position: "fixed",
          width: "100%",
          top: 0,
          zIndex: 1,
          background: "#ffffff",
        }}
      >
        <Navbar />
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "none",
          },
          position: "fixed",
          width: "100%",
          top: 0,
          zIndex: 1,
          background: "#ffffff",
        }}
      >
        <MobileHeader />
      </Box>
      {/* content area */}
      {/* Content Area Start */}
      <Box
        sx={{
          height: "100vh",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "1440px",
            margin: "0px auto",

            paddingTop: "60px",
            paddingBottom: {
              xs: "70px",
              sm: "0px",
            },
          }}
        >
          {children}
        </Box>
        <Grid
          container
          sx={{
            width: "100%",
            display: {
              xs: "none",
              sm: "block",
            },
          }}
        >
          <Grid item xs={12}>
            <FooterPrimary />
          </Grid>
        </Grid>
      </Box>
      {/* Content Area End */}
      <Grid
        container
        sx={{
          position: "fixed",
          width: "100%",
          bottom: 0,
          zIndex: 2,
          background: "#ffffff",
          display: {
            sm: "none",
          },
        }}
      >
        <Grid
          item
          sx={{
            width: { xs: "100%", sm: "100%" },

            marginTop: "auto",
          }}
        >
          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "none",
              },

              borderTop: "1px solid #eaecf0",
              alignItems: "center",
              paddingTop: "3px",
              paddingBottom: "2px",
            }}
          >
            <NavbarItems />
          </Box>
        </Grid>
      </Grid>
      {/* <Grid container sx={{
        position:"fixed",
        width:"100%",
        bottom:0,
        background:"#ffffff"
      }}>
              <Grid
                item
                sx={{
                  width: { xs: "100%", sm: "100%" },

                  marginTop: "auto",
                }}
              >
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                  }}
                >
                  <DesktopFooter />
                </Box>
                <Box
                  sx={{
                    display: {
                      xs:"flex",
                      sm: "none",
                    },
                    borderTop:"1px solid #eaecf0",
                    alignItems:"center",
                    paddingTop:"10px",
                    paddingBottom:"10px"
                  }}
                >
                  <NavbarItems />
                </Box>
              </Grid>
            </Grid> */}
    </div>
  );
};
